/* eslint-disable no-unused-vars */
import { configureStore } from '@reduxjs/toolkit';

import AdminFunctionManager from './AdminFunctionReducer';
import AdminManager from './AdminReducer';
import AuthSlice from './AuthReducer';
import BatchManager from './BatchModeReducer';
import BullsEyeReducer from './BullEyeReducer';
import ExamAttemptSlice from './ExamAttemptSlice';
import ExamSlice from './ExamSlice';
import imageReducer from './ImageReducer';
import instituteReducer from './InstituteReducer';
import KYCReducer from './KycReducer';
import LeaderBoardReducer from './LeaderBoardReducer';
import NotesReducer from './Notes/NotesReducer';
import InstructionManager from './PaperManager/InstructionReducer';
import PaperAnalysisReducer from './PaperManager/PaperAnalysisReducer';
import PaperEditorReducer from './PaperManager/PaperEditorReducer';
import { default as PaperGeneratorReducer, default as workSheetFilterReducer } from './PaperManager/PaperGeneratorReducer';
import Payment from './Payment/PaymentReducer';
import SpecialPaperManager from './PublicPagesManager/SpecialPaperManager';
import AnswerManager from './QuestionManager/AnswerReducer';
import BloomtaxonomyManager from './QuestionManager/BloomtaxonomyReducer';
import BoardManager from './QuestionManager/BoardReducer';
import BookManager from './QuestionManager/BookReducer';
import ChapterManger from './QuestionManager/ChapterReducer';
import NewQuestionManager from './QuestionManager/NewQuestionReducer';
import PriceDistributionManager from './QuestionManager/PriceDistributionReducer';
import QuestionNatureManager from './QuestionManager/QuestionNatureReducer';
import QuestionManager from './QuestionManager/QuestionReducer';
import SourceManger from './QuestionManager/SourceReducer';
import StandardManager from './QuestionManager/StandardReducer';
import SubjectChapterManager from './QuestionManager/SubjectChapterReducer';
import SubjectManager from './QuestionManager/SubjectReducer';
import SubTopicManager from './QuestionManager/SubTopicReducer';
import TagsManager from './QuestionManager/TagsReducer';
import TopicManager from './QuestionManager/TopicReducer';
import SpecialPaperReducer from './SpecialPaperReducer';
import StreakSlice from './StreakSlice';
import EcommerceReducer from './template/eCommerce/EcommerceSlice';
import ExamWorkSheetSlicer from './template/Question/ExamWorkSheetSlice';
import TestSeriesManager from './TestSeriesManager/TestSeriesReducer';
import CustomizerReducer from './theme/ThemeSlice';
import ExamFlowReducer from './TournamentAttemptSlice';
import TournamentSlice from './TournamentSlice';
import UpiReducer from './UpiReducer';
import UserProfileInfoReducer from './UserInfoReducer';
import UserManagerReducer from './UserManagerReducer';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    SpecialPaper: SpecialPaperManager,
    instituteReducer: instituteReducer,
    imageReducer: imageReducer,
    // contactsReducer: ContactsReducer,
    // ticketReducer: TicketReducer,
    ecommerceReducer: EcommerceReducer,
    // userpostsReducer: UserProfileReducer,
    // blogReducer: BlogReducer
    // tableReducer: TableReducer,
    paperGenerator: PaperGeneratorReducer,
    worksheetfilter: workSheetFilterReducer,

    counter: QuestionManager,
    examAttempt: ExamAttemptSlice,
    Exam: ExamWorkSheetSlicer,
    paperAnalysis: PaperAnalysisReducer,
    SpecialPaper1: SpecialPaperReducer,

    worksheet: ExamSlice,
    Auth: AuthSlice,
    UserManager: UserManagerReducer,
    UserInfo: UserProfileInfoReducer,

    Question: QuestionManager,
    QuestionNature: QuestionNatureManager,
    Standard: StandardManager,
    Topic: TopicManager,
    SubTopic: SubTopicManager,
    Subject: SubjectManager,
    Answer: AnswerManager,
    Bloom: BloomtaxonomyManager,

    AdminFunction: AdminFunctionManager,

    TestSeries: TestSeriesManager,
    PriceDistribution: PriceDistributionManager,
    Board: BoardManager,
    Book: BookManager,
    Chapter: ChapterManger,
    Source: SourceManger,
    Tags: TagsManager,
    createNewQuestion: NewQuestionManager,
    subjectChapter: SubjectChapterManager,
    Payment: Payment,
    Instruction: InstructionManager,
    PaperEditor: PaperEditorReducer,
    Notes: NotesReducer,
    KYC: KYCReducer,
    UPI: UpiReducer,
    Batch: BatchManager,
    BullsEye: BullsEyeReducer,
    LeaderBoard: LeaderBoardReducer,
    Admin: AdminManager,
    Tournament: TournamentSlice,
    ExamFlow: ExamFlowReducer,
    Streak: StreakSlice,
  },
});

export default store;
