
import { createSlice } from '@reduxjs/toolkit';
import { createStreakGeneratorAPI, getStreakLeaderboardListAPI } from 'src/service/StreakService';

const initialState = {
    streakData: {},
    streakLeaderBoardData: []
}

const StreakSlice = createSlice({
    name: 'Streak',
    initialState,
    reducers: {
        setStreakData: (state, action) => {
            state.streakData = action.payload
        },
        setStreakLeaderBoardData: (state, action) => {
            state.streakLeaderBoardData = action.payload;
        },
    }
})

export const {
    setStreakData,
    setStreakLeaderBoardData
} = StreakSlice.actions;


export const getStreakLeaderboardListAction = (param) => async (dispatch) => getStreakLeaderboardListAPI(param)
export const createStreakGeneratorAction = (param) => async (dispatch) => createStreakGeneratorAPI(param);

export default StreakSlice.reducer;