import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { createTestSeriesAPI, getAllTestListAPI, getPurchasedTestSeriesListAPI, purchaseTestSeriesAPI, retrieveTestSeriesAPI, updateTestSeriesAPI } from 'src/service/TestSeriesService';

const initialState = {
  seriesTests: [],
  seriesPreviewTests: [],
  allTestSeries: [],
};

export const TestSeriesManager = createSlice({
  name: 'TestSeries',
  initialState,
  reducers: {
    addSeriesTest: (state, action) => {
      state.seriesTests = [...action.payload];
    },
    addSeriesPreviewTests: (state, action) => {
      state.seriesPreviewTests = [...action.payload];
    },
    deleteSeriesTest: (state, action) => {
      const index = state.seriesTests.findIndex((obj) => obj.id === action.payload?.id);
      if (index !== -1) state.seriesTests.splice(index, 1); // Only remove if found
    },
    deleteSeriesPreviewTests: (state, action) => {
      const index = state.seriesPreviewTests.findIndex((obj) => obj.id === action.payload?.id);
      if (index !== -1) state.seriesPreviewTests.splice(index, 1);
    },
    // New toggle reducer
    toggleSeriesTest: (state, action) => {
      const index = state.seriesTests.findIndex((obj) => obj.id === action.payload?.id);
      if (index !== -1) {
        state.seriesTests.splice(index, 1);
        const index2 = state.seriesPreviewTests.findIndex((obj) => obj.id === action.payload?.id);
        if (index2 !== -1) {
          state.seriesPreviewTests.splice(index2, 1);
        }
      } else {
        // If not, add it
        state.seriesTests.push(action.payload);
      }
    },

    togglePreviewSeriesTest: (state, action) => {
      const index = state.seriesPreviewTests.findIndex((obj) => obj.id === action.payload?.id);
      if (index !== -1) {
        // If the test is already in the list, remove it
        state.seriesPreviewTests.splice(index, 1);
      } else {
        // If not, add it
        if (state?.seriesPreviewTests?.length > 2) {
          enqueueSnackbar("You can't add more then 3 free tests", { variant: 'error' });
        } else {
          state.seriesPreviewTests.push(action.payload);
        }
      }
    }
  },
});

export const {
  addSeriesTest,
  addSeriesPreviewTests,
  deleteSeriesTest,
  deleteSeriesPreviewTests,
  toggleSeriesTest,
  togglePreviewSeriesTest
} = TestSeriesManager.actions;

// Thunks for API calls
export const getAllTestListAPIAction = (param) => async (dispatch) => getAllTestListAPI(param);

export const getPurchasedTestSeriesListAPIAction = (param) => async (dispatch) => getPurchasedTestSeriesListAPI(param);

export const retrieveTestSeriesAPIAction = (id) => async (dispatch) => retrieveTestSeriesAPI(id);

export const createTestSeriesAPIAction = (param) => async (dispatch) => createTestSeriesAPI(param);

export const purchaseTestSeriesAPIAction = (param) => async (dispatch) => purchaseTestSeriesAPI(param);

export const updateTestSeriesAPIAction = (param) => async (dispatch) => updateTestSeriesAPI(param);

export default TestSeriesManager.reducer;
