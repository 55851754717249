import axios from 'axios';
import { REACT_APP_BACKEND_URL } from 'src/utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let headersList = { Accept: '*/*' };




export const sendNotificationAPI = async (param) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/send-notification/`,
    method: 'POST',
    headers: headersList,
    data: JSON.stringify({ ...param }),
  };

  const response = await axios.request(reqOptions);
  return response;
};

export const sendSystemNotificationAPI = async (param) => {
  const reqOptions = {
    url: `${REACT_APP_BACKEND_URL}/schedule-task/`,
    method: 'POST',
    headers: headersList,
    data: JSON.stringify({ ...param }),
  };

  const response = await axios.request(reqOptions);
  return response;
};

