// export const REACT_APP_BACKEND_URL = 'https://backend-service-zk7nvleihq-df.a.run.app';
// export const REACT_APP_BACKEND_URL = 'http://127.0.0.1:8000/';
// export const REACT_APP_BACKEND_URL = 'https://backend.shauryats.com';
export const REACT_APP_BACKEND_URL = 'https://backend-service-254874696674.asia-south2.run.app';
// export const REACT_APP_BACKEND_URL =  'https://backend-service-prod-olm5wi65da-em.a.run.app';

export const ANSWER_LENGTH = {
  VSA: 'Very Short Answer',
  SA: 'Short Answer',
  LA: 'Long Answer',
  VLA: 'Very Long Answer',
};

export const ACTIVITY_STATUS = {
  true: 'Active',
  false: 'Disabled',
};

export const USER_TYPE = {
  0: 'Teacher',
  1: 'Student',
  2: 'Staff',
};

export const PRICING_TIER = {
  Free: 'Free',
  Paid: 'Paid',
  Premium: 'Premium',
};

export const PRICING = {
  Free: false,
  Paid: true,
  Both: '',
};

export const QUESTION_TYPE = {
  MCQ: 'MCQ',
  Subjective: 'Subjective',
};

export const WORKSHEET_TYPE = {
  bullseye: `Bull's Eye`,
  exam: 'Exam',
  worksheet: 'Worksheet',
};

export const WORKSHEET_TYPE_TEACHER = {
  exam: 'Exam',
  class: 'Class Tournament',
  test: 'Worksheet',
  test_series:'Test series'
};

export const WORKSHEET_ONLINE_TEACHER = {
  exam: 'Exam',
  test: 'Worksheet',
};

export const WORKSHEET_TYPE_STUDENT = {
  exam: 'Exam',
  bullseye: `Bull's Eye`,
  test: 'Worksheet',
};

// export const WORKSHEET_SUB_TYPE = {
//   unit: 'Unit Test',
//   class: 'Class Test',
//   surprise: 'Surprise Test',
//   bullseye: `Bull's Eye Test`,
//   practice: 'Practice Test',
//   homework: 'Assignment',
//   revision: 'Revision',
//   competitive: 'Competitive Exam',
//   annual: 'Annual Exam',
//   half_yearly: 'Half Yearly Exam',
//   quarter: 'Quarterly Exam',
// };

export const WORKSHEET_SUB_TYPE = {
  practice: 'Practice',
  homework: 'Homework',
  revision: 'Revision',
  annual: 'Annual',
};

export const SUB_TYPES = {
  test: [
    { name: 'Unit Test', id: 'unit' },
    { name: 'Class Test', id: 'class' },
    { name: 'Surprise Test', id: 'surprise' },
    { name: `Bull's Eye Test`, id: 'bullseye' },
    { name: 'Practice Test', id: 'practice' },
  ],
  exam: [
    { name: 'Competitive Exam', id: 'competitive' },
    { name: 'Half Yearly Exam', id: 'half_yearly' },
    { name: 'Annual Exam', id: 'annual' },
    { name: 'Quarterly Exam', id: 'quarter' },
  ],
  worksheet: [
    { name: 'Assignment', id: 'homework' },
    { name: 'Revision', id: 'revision' },
  ],
  bullseye: [],
};

export const DIFFICULTY = {
  easy: 'Easy',
  medium: 'Medium',
  hard: 'Hard',
};
export const VISIBILITY = {
  private: 'Only Me',
  protected: 'My Institute',
  public: 'Public',
};

export const PriceDistributionStatus = {
  pending: 'Pending',
  approved: 'Approved',
  rejected: 'Rejected',
};

export const QUESTION_STATUS = {
  approved: 'Approved',
  draft: 'Draft',
  pending: 'Pending',
  rejected: 'Rejected',
  reviewed: 'Reviewed',
};

export const QUESTION_STATUS_FOR_USER = {
  approved: 'Approved',
  pending: 'Pending',
};

export const INSTRUCTION_TYPE = {
  footer: 'Footer',
  header: 'Header',
};

export const CREATED_MODE = {
  express: 'Express',
  cherrypick: 'Cherry Pick',
};
export const MODE = {
  objective: 'Online',
  Subjective: 'Offline',
};

export const LOGOPOSITION = {
  L: 'Left',
  C: 'Center',
  R: 'Right',
};

export const PAPERVISIBILITY = {
  private: 'Only Me',
  public: 'Public',
  protected: 'Specific Users',
  // batch: 'Batch Users',
};

export const SERIES_TAGS = {
  JEE_Mains:"JEE Mains",
  JEE_Advance :"JEE Advance",
  NDA:"NDA",
  NEET:"NEET",
  CUET:"CUET",
  Board:"BOARD",
  Government_Exams:"Government Exams",
  Other:"Other"
};

export const TEST_PATH = {
  "notification.tasks.send_notification_based_on_user_type": "Send notification based on User Type",
  // "notification.tasks.distribute_reward_of_tournament" :"Send notification for distribute_reward_of_tournament",
  // "notification.tasks.send_notification_based_on_standard": "Send notification based on standard",
  // "notification.tasks.send_notification_based_on_user_or_standard":"Send notification based on user or standard"
}


export const Schedule_type = {
  "interval": "Interval",
  "crontab": "In Regular Schedule",
  "one_time":"One Time"
}

export const PERIOD = {
  "IntervalSchedule.HOURS":"Hours",
  "IntervalSchedule.MINUTES": "Minutes",
  "IntervalSchedule.SECONDS": "Seconds",
}