import axios from 'axios';
import { REACT_APP_BACKEND_URL } from '../utils/constant';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
let headersList = { Accept: '*/*' };


export const getStreakLeaderboardListAPI = async (url) => {
    const response = await axios.get(url ?? `${REACT_APP_BACKEND_URL}/streak/`);
    return response;
};

export const createStreakGeneratorAPI = async (param) => {
    const reqOptions = {
        url: `${REACT_APP_BACKEND_URL}/streak/`,
        method: 'POST',
        headers: headersList,
        data: JSON.stringify({ ...param }),
    };
    const response = axios.request(reqOptions);
    return response;
};