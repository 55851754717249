


import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyC8umQzTrNJrvGdQ4EmyH5qrEfGEPw6EIU",
    authDomain: "shaurya-4cccf.firebaseapp.com",
    projectId: "shaurya-4cccf",
    storageBucket: "shaurya-4cccf.appspot.com",
    messagingSenderId: "266548748807",
    appId: "1:266548748807:web:553a085897d2eb778680a2",
    measurementId: "G-8LTHPFYF3W"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { getToken, messaging, onMessage };



export const generateToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        console.log("Notification permission:", permission);

        if (permission === 'granted') {

            const token = await getToken(messaging, {
                vapidKey: "BC5wQavtjKnSoQMjCv2TFHqLrdDmKVbXAeMqvZKjl37h0aYrn4gMapGSCGjuQ-CU5MFHVse_WA-ZU9XlJRhC2SA"
            });
            console.log("FCM Token:", token);
        } else {
            console.error("Permission not granted for notifications");
        }
    } catch (err) {
        console.error("Error generating token:", err);
    }
};


